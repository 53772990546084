<template>
  <div class="footer-container">
    <!-- Newsletter -->
    <div class="footer-newsletter-content">
      <Newsletter class="w-full lg:w-auto lg:min-w-[350px]" />
    </div>

    <!-- Footer -->
    <div class="container footer-content" v-if="isShowFooter">
      <div class="footer-inner-wrapper">
        <template v-if="Array.isArray(footerMenu) && footerMenu.length > 0">
          <div class="sm:block hidden" v-for="i in footerMenu" :key="`ftm-${i.id}`">
            <ul role="list" class="text-left">
              <h3 class="text-base font-bold text-primary-400 mb-2 text-left tracking-wider uppercase" v-if="getLocaleString(i.name)">
                {{ getLocaleString(i.name) }}
              </h3>

              <li v-for="item in i.children" :key="`ftmc-${item.id}`">
                <NuxtLinkLocale
                  :to="getLocaleString(item.href, true)"
                  class="text-base text-primary-400 hover:underline"
                >
                  {{ getLocaleString(item.name) }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div class="sm:hidden block">
            <TemplatesFooterMobileFooter/>
          </div>
        </template>
        <div class="space-y-6" v-if="
          footerConfig?.social_media_links &&
          Array.isArray(footerConfig.social_media_links)
        ">
          <div class="flex flex-wrap space-x-1 md:space-x-3">
            <a
              :key="`nwm-${idx}`"
              :href="getLocaleString(item.link)"
              class="fill-primary-400 hover:bg-primary hover:fill-white border rounded-full p-2"
              v-for="(item, idx) in footerConfig.social_media_links"
            >
              <NuxtImg class="size-6 object-contain" :src="item.icon" :alt="getLocaleString(item.name)" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="container footer-html-area" v-if="getLocaleString(footerConfig?.legal_notice_text)" v-html="getLocaleString(footerConfig?.legal_notice_text)"></div>

    <!-- Copyright -->
    <Copyright />
  </div>
</template>

<script setup>
const { isWholesaleUser } = await useCustomer();
const { isShowFooter } = useHelper();

const storeConfig = useStoreConfig()
const { footerMenu, footerConfig } = storeToRefs(storeConfig)

</script>

<style lang="scss">
.footer-container {
  @apply flex flex-col text-primary;
}

.footer-content {
  @apply pt-6 sm:pt-12 pb-2.5 px-4;
}

.footer-html-area {
  @apply text-sm py-4 text-primary;
}

.footer-inner-wrapper {
  @apply sm:grid sm:grid-cols-5 sm:space-x-10 sm:grid-flow-col auto-cols-max space-y-0 sm:space-y-10 md:space-y-0 max-w-screen-2xl mx-auto;
}
</style>
